<script setup>
import { computed } from 'vue'

const emit = defineEmits(['update:checked'])

const props = defineProps({
    checked: {
        type: [Array, Boolean],
        required: true
    },
    value: {
        default: null
    }
})

const proxyChecked = computed({
    get () {
        return props.checked
    },

    set (val) {
        emit('update:checked', val)
    }
})
</script>

<template>
    <input
        v-model="proxyChecked"
        type="checkbox"
        :value="value"
        class="rounded border-gray-200 text-red-primary shadow-sm focus:ring-red-secondary"
    />
</template>
